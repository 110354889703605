exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-couples-counselling-js": () => import("./../../../src/pages/couples-counselling.js" /* webpackChunkName: "component---src-pages-couples-counselling-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-therapy-js": () => import("./../../../src/pages/individual-therapy.js" /* webpackChunkName: "component---src-pages-individual-therapy-js" */),
  "component---src-pages-sex-therapy-js": () => import("./../../../src/pages/sex-therapy.js" /* webpackChunkName: "component---src-pages-sex-therapy-js" */)
}

